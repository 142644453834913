import error404 from "../assets/illustrations/error-404.png";

export const CerezaNotAccess = () => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <h2>
            It appears that you are currently not allowed access to any
            applications, please contact human resources
          </h2>
          <h2>
            Parece que de momento no tienes acceso permitido a ninguna
            aplicación, comunícate con recursos humanos
          </h2>
        </div>
        <br />
        <img width="50%" src={error404} alt="Bilding" />
      </Box>
    </>
  );
};
