import { httpClient } from "../setupAxios";

const AUTH = "/v1/auth";
const LOGOUT = "/v1/auth/logout";
const RECOVERY = "/v1/auth/password/confirm/recovery";
const RECOVERY_EMAIL = "/v1/auth/password/recovery";

export function getPermissions(userId) {
  return httpClient.get(`${AUTH}/cereza/permissions/user/${userId}`);
}

export function postLogOut(body) {
  return httpClient.post(LOGOUT, body);
}

export function tenantSignUp(newTenant) {
  return httpClient.post("/v1/boff/tenants/register/sign-up", newTenant);
}

export function postRecovery(body) {
  return httpClient.post(RECOVERY, body);
}

export function postRecoveryEmail(body) {
  return httpClient.post(RECOVERY_EMAIL, body);
}
