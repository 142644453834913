import { ContactDetails as OrgContactDetails } from "modules/Employees/Profiles/Collaborator/ContactDetails";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const ContactDetails = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgContactDetails
      application="employee"
      user={{ employeeSelected: user }}
    />
  );
};
