import {
  Box,
  Typography,
  Divider,
  Avatar,
  Stack,
  MenuItem,
  Popover,
  MenuList,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import avatar from "assets/illustrations/avatar.jpeg";
import LockResetIcon from "@mui/icons-material/LockReset";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { parameterizationSelect, setLogo } from "@redux/slices/parameterization";
import { getFiles } from 'api';
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { QrCard } from "../../../cereza-organization/src/app/components/QrCard";
import { useEffect, useRef } from "react";
import { Language } from "./Language";
import { SignOut } from "@keycloak/logout";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import Swal from "sweetalert2";

export const MenuAvatar = ({
  openAvatar,
  anchorEl,
  handleClose,
  user,
  userThumbnail,
  handleUpdatePassword,
}) => {
  const { t } = useTranslation(["sidenav"]);

  const { keycloakProfile, permissions } = useSelector(authUserSelect);
  const { tenant } = useSelector(tenantSettingsSelect);
  const { logo } = useSelector(parameterizationSelect);
  const dispatch = useDispatch();
  const cardRef = useRef(null)

  const handleDownload = () => {
    const qrCard = cardRef.current;
    if (qrCard) {
      html2canvas(qrCard, { backgroundColor: "#fff", scale: 2}).then((canvas) => {
        const dataUrl = canvas.toDataURL('image/png');
        const document = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: [175, 244],
        });

        document.addImage(dataUrl, 'PNG', 0, 0, 175, 244);
        document.save('Credencial.pdf');
      })
      .catch((error) => {
        console.log("Error al generar el canvas", error);
      });
    }
  }

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant._id, tenant.companyLogo);
        dispatch(setLogo(data.content));
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (logo === null && tenant?._id && tenant?.companyLogo) {
      fetchSource();
    }
  }, [])

  return (
    <Popover
      open={openAvatar}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleClose}
      sx={{
        mt: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          margin: "15px",
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Avatar
            alt="Remy Sharp"
            src={userThumbnail ?? avatar}
            sx={{ width: 124, height: 124 }}
          />
          <Box mt={2} />
          <Typography>
            {user?.names} {user?.surnames}
          </Typography>
          <Typography>{user?.email}</Typography>
        </Stack>
        <Divider />
        <MenuList sx={{ width: 320, maxWidth: "100%" }}>
          <MenuItem onClick={handleDownload}>
            <ListItemIcon>
              <QrCodeScannerIcon />
            </ListItemIcon>
            <ListItemText>{t("QR")}</ListItemText>
            <Box sx={{ position: 'absolute', top: '-10000px', left: '-10000px', }}>
              <Box ref={cardRef}>
                <QrCard
                  fullName={user?.names + " " + user?.surnames}
                  employee={user?.number}
                  avatarUrl={logo}
                />
              </Box>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={() => {
              Swal.fire({
                title: t("sidenav:Are"),
                text: t("sidenav:Youwon"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("sidenav:Continuar"),
              }).then((result) => {
                if (result.isConfirmed) {
                  handleUpdatePassword();
                }
              });
            }}
          >
            <ListItemIcon>
              <LockResetIcon />
            </ListItemIcon>
            <ListItemText>{t("CambiarContraseña")}</ListItemText>
          </MenuItem>
          {(keycloakProfile?.is_superuser ||
            permissions?.apps?.organization === true) && (
            <MenuItem
              onClick={() => {
                window.location.href = "/cereza-organization/home/indicators";
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText>{t("CerezaOrganización")}</ListItemText>
            </MenuItem>
          )}
          <Language />
          <Divider />
          <MenuItem
            onClick={() => {
              SignOut();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t("CerrarSesion")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Stack>
    </Popover>
  );
};
