export const UI_STATES = Object.freeze({
  IS_VIEWING: "viewing",
  IS_EDITING: "editing",
  IS_CREATING: "creating",
});

export const API_ACTIONS = Object.freeze({
  INIT: "",
  IS_LOADING: "loading",
  IS_DELETING: "deleting",
  IS_SAVING: "saving",
  IS_UPDATING: "updating",
  IS_SEARCHING: "searching",
  IS_SELECTED: "selected",
});

export const MESSAGE_TYPES = Object.freeze({
  INIT: "",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
  NEW_DATA: "new_data",
});

export const CLIENT_ID = "web-app-cli";

export const GENDER_OPTIONS = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "OTHER", label: "Other" },
];

export const BLOOD_TYPE_OPTIONS = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
];

export const CIVIL_STATUS_OPTIONS = [
  { value: "SINGLE", label: "Single" },
  { value: "MARRIED", label: "Married" },
  { value: "DIVORCED", label: "Divorced" },
  { value: "WIDOWED", label: "Widowed" },
  { value: "OTHER", label: "Other" },
];

export const LEVELS = [
  { value: "Expert", label: "Expert" },
  { value: "Advanced", label: "Advanced" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Basic", label: "Basic" },
];

export const DOCUMENT_OPTIONS = [
  { value: "PASSPORT", label: "Passport" },
  { value: "INE", label: "INE" },
  { value: "DNI", label: "DNI" },
  { value: "CURP", label: "CURP" },
  { value: "CITIZENSHIP_CERTIFICATE", label: "Citizenship certificate" },
  { value: "IDENTIFICATION_CARD", label: "Identification card" },
  { value: "RESIDENCE_PERMIT", label: "Residence permit" },
];

export const RELATIONSHIP_OPTIONS = [
  { value: "FATHER", label: "Father" },
  { value: "MOTHER", label: "Mother" },
  { value: "SON_DAUGHTER", label: "Son/Daughter" },
  { value: "GRANDFATHER_GRANDMOTHER", label: "Grandfather/Grandmother" },
  { value: "GRANDSON_GRANDDAUGHTER", label: "Grandson/Granddaughter" },
  { value: "BROTHER_SISTER", label: "Brother/Sister" },
  { value: "UNCLE_AUNT", label: "Uncle/Aunt" },
  { value: "COUSIN", label: "Cousin" },
  { value: "FRIEND", label: "Friend" },
  { value: "HUSBAND_WIFE", label: "Husband/Wife" },
];

export const SOCIAL_NETWORKS = [
  { value: "FACEBOOK", label: "Facebook" },
  { value: "X", label: "X" },
  { value: "INSTAGRAM", label: "Instagram" },
  { value: "LINKEDIN", label: "LinkedIn" },
  { value: "TIKTOK", label: "TikTok" },
  { value: "SNAPCHAT", label: "Snapchat" },
  { value: "PINTEREST", label: "Pinterest" },
  { value: "YOUTUBE", label: "Youtube" },
];

export const STUDY_LEVELS = [
  { value: "PRIMARY", label: "Primary" },
  { value: "SECUNDARY", label: "Secondary" },
  { value: "PREPARATORY", label: "Preparatory" },
  { value: "DEGREE", label: "Degree" },
  { value: "MASTER", label: "Master" },
  { value: "DOCTORATE", label: "Doctorate" },
];

export const IMPACT_CHOICES = [
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
];

export const USAGE_TYPE_CHOICES = [
  { value: "permanent", label: "Permanent" },
  { value: "loaner", label: "Loaner" },
];
export const TIME_CHECK_TYPES = [
  { value: "WEB", label: "Web" },
  { value: "FACIAL_RECOGNITION", label: "ReconocimientoFacial" },
  { value: "MOBILE", label: "AppMobile" },
  { value: "FINGERPRINT", label: "HuellaDactilar" },
  //? info: los siguientes valores se usaran a futuro
];
