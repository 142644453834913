import { PersonalInformation } from "modules/Employee/Profiles/Collaborator/PersonalInformation";
import { ContactDetails } from "modules/Employee/Profiles/Collaborator/ContactDetails";
import { IdentitiesDocuments } from "modules/Employee/Profiles/Collaborator/IdentitiesDocuments";
import { AcademyHistory } from "modules/Employee/Profiles/Competencies/AcademicHistory";
import { SkillsSummary } from "modules/Employee/Profiles/Competencies/SkillsSummary";
import { CurrentEmployment } from "modules/Employee/Profiles/EmploymentInformation/CurrentEmployment";
import { EmploymentsSummary } from "modules/Employee/Profiles/EmploymentInformation/EmploymentsSummary";
import { RecordHistory } from "modules/Employee/Profiles/RecordHistory";
import { Assets } from "modules/Employee/Profiles/Assets";
import { Documents } from "modules/Employee/Profiles/Documents";
import { Compensation } from "modules/Employee/Profiles/Compensation";
import { TimeClock } from "modules/Employee/Profiles/TimeClock";
import { EmployeeNavigationSource } from "./app/modules/Settings/Security/CreateGroups/EmployeeNavigationSource";
export {EmployeeNavigationSource};
function generateRoutesFromNavigation(source) {
  source = (!Array.isArray(source)) ? Array(source) : source;
  return source.reduce((routes, item) => {
    let accumulatedRoutes = routes;

    if (item.tree) {
      const childRoutes = generateRoutesFromNavigation(item.tree);
      accumulatedRoutes = accumulatedRoutes.concat(childRoutes);
    }

    if (item.is_nav) {
      item.name = {
        "/profile/collaborator/personal-information": "InformacionGeneral"
      }[item.path] || item.name;

      const routeConfig = {
        name: item.name,
        route: item.path,
        component: item.component,
      };
      accumulatedRoutes.push(routeConfig);
    }

    return accumulatedRoutes;
  }, []);
}

export const HOME =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Inicio").tree);

export const REQUESTS =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Requests"));

export const ACCOUNT =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Cuenta").tree);

export const PROFILE =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Perfil").tree);

export const SHIFT_ATTENDANCE =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "TurnoDeAsistencia").tree);

export const LEARNING_CENTER =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "CentroDeAprendizaje").tree);

export const PERFORMANCE_CENTER =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "CentroDeRendimiento").tree);

export const RESOURCES =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Recursos").tree);

export const MEDICAL =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Médico").tree);

export const TOOLS =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "Herramientas").tree);

export const SPECIAL_ROUTES =
  generateRoutesFromNavigation(EmployeeNavigationSource.find((val) => val.name === "RutasEspeciales").tree);


const PATH_PROFILE = "/profile";
const PATH_COLLABORATOR = `${PATH_PROFILE}/collaborator`;
const PATH_COMPETENCIES = `${PATH_PROFILE}/competencies`;
const PATH_EMPLOYMENT = `${PATH_PROFILE}/employment-information`;

export const PROFILE_MODULE = {
  Collaborator: {
    name: "Collaborator",
    sections: [
      {
        name: "PersonalInformation",
        route: `${PATH_COLLABORATOR}/personal-information`,
        component: <PersonalInformation />,
      },
      {
        name: "ContactDetails",
        route: `${PATH_COLLABORATOR}/contact-details`,
        component: <ContactDetails />,
      },
      {
        name: "IdentitiesDocuments",
        route: `${PATH_COLLABORATOR}/identities-documents`,
        component: <IdentitiesDocuments />,
      },
    ],
  },
  Competencies: {
    name: "Competencies",
    sections: [
      {
        name: "AcademyHistory",
        route: `${PATH_COMPETENCIES}/academy-history`,
        component: <AcademyHistory />,
      },
      {
        name: "SkillsSummary",
        route: `${PATH_COMPETENCIES}/skills-summary-tab`,
        component: <SkillsSummary />,
      },
    ],
  },
  EmploymentInformation: {
    name: "EmploymentInformation",
    sections: [
      {
        name: "CurrentEmployment",
        route: `${PATH_EMPLOYMENT}/current-employment`,
        component: <CurrentEmployment />,
      },
      {
        name: "EmploymentSummary",
        route: `${PATH_EMPLOYMENT}/employment-summary`,
        component: <EmploymentsSummary />,
      },
    ],
  },
  Compensation: {
    name: "Compensation",
    route: `${PATH_PROFILE}/compensation`,
    component: <Compensation />,
    sections: [],
  },
  TimeClock: {
    name: "TimeClock",
    route: `${PATH_PROFILE}/time-clocks`,
    component: <TimeClock />,
    sections: [],
  },
  Documents: {
    name: "Documents",
    route: `${PATH_PROFILE}/documents`,
    component: <Documents />,
    sections: [],
  },
  Assets: {
    name: "Assets",
    route: `${PATH_PROFILE}/assets`,
    component: <Assets />,
    sections: [],
  },
  RecordHistory: {
    name: "RecordHistory",
    route: `${PATH_PROFILE}/record-history`,
    component: <RecordHistory />,
    sections: [],
  },
};
