import * as React from "react";
import { Stack, Typography } from "@mui/material";
import {
  Copyright as CopyrightIcon,
  Favorite as FavoriteIcon
} from "@mui/icons-material";

export const FooterCopyright = () => {
  const now = new Date();

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="center" spacing={1} mt={2}>
        <Typography variant="body2" color="text.secondary" align="center" >
          {now.getFullYear()}
        </Typography>
        <CopyrightIcon fontSize="small" />
        <Typography variant="body2" color="text.secondary" align="center" >
          made with
        </Typography>
        <FavoriteIcon fontSize="small" />
        <Typography variant="body2" color="text.secondary" align="center" >
          by Cereza Soft LLC
        </Typography>
      </Stack>
    </>
  )
}
