import { AcademyHistory as OrgAcademyHistory } from "modules/Employees/Profiles/Competencies/AcademicHistory";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const AcademyHistory = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgAcademyHistory
      application="employee"
      user={{ employeeSelected: user }}
    />
  );
};
