import { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, Paper, TableCell } from "@mui/material";
import { Filter } from "components/Filter";
import { SearchBar, BasicTable } from "components";
import { useTranslation } from "react-i18next";
import { getCoworker } from "api";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEmployeeSelected } from "@redux/slices/profile";

export const Directory = () => {
  const { t } = useTranslation(["home"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(authUserSelect);

  const [openFilterPage, setOpenFilterPage] = useState(true);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [fullData, setFullData] = useState(null);
  const [filteredData, setFilteredData] = useState(dataEmployees);
  const [filters, setFilters] = useState({
    hasFilter: false,
    groupsConfiguration: {},
    groups: {},
  });

  useEffect(() => {
    const lista = async () => {
      try {
        let { data } = await getCoworker();
        setFullData(data);

        data = Object.keys(data).map((key) => data[key]);
        data.filter((obj) => obj.email !== user.email);

        let colegas = data.map((item) => ({
          id: item._id,
          thumbnail: item.thumbnail,
          fullName: item.fullName,
          department: item.department === "" ? "NA" : item.department,
          position: item.position,
          cellphoneNumber: item.cellphoneNumber,
          extensionNumber: item.extensionNumber,
          telephoneNumber: item.telephoneNumber,
        }));

        setDataEmployees(colegas);

        setFilters({
          hasFilter: false,
          groupsConfiguration: {
            department: {
              title: "Departamento",
              options: [
                ...new Set(colegas.map((item) => item.department)),
              ].filter((el) => el !== undefined),
              type: "checkbox",
            },
          },
          groups: {
            department: new Set(),
          },
        });
      } catch (error) {
        console.error(error);
      }
    };

    lista();
  }, []);

  const LetMeSee = async (worker) => {
    dispatch(setEmployeeSelected(fullData[worker.id]));
    navigate("/presentation-letter");
  };

  return (
    <>
      <Box>
        {dataEmployees.length > 0 ? (
          <>
            <Grid container spacing={3} justifyContent="center">
              <Grid item={true} xs={12} md={10}>
                <SearchBar
                  data={dataEmployees}
                  searchKeys={["fullName"]}
                  setFilteredData={setFilteredData}
                />
                <br />
                <BasicTable
                  rows={filteredData.length > 0 ? filteredData : dataEmployees}
                  handleClick={(row) => LetMeSee(row)}
                  PerPage={25}
                >
                  <TableCell></TableCell>
                  <TableCell>{t("employee:fullName")}</TableCell>
                  <TableCell>{t("employee:department")}</TableCell>
                  <TableCell>{t("employee:position")}</TableCell>
                  <TableCell>{t("employee:cellphoneNumber")}</TableCell>
                  <TableCell>{t("employee:extensionNumber")}</TableCell>
                  <TableCell>{t("employee:telephoneNumber")}</TableCell>
                </BasicTable>
              </Grid>

              <Grid item={true} xs={12} md={2}>
                {openFilterPage && (
                  <Paper>
                    <Filter
                      title={"Filtro de empleados"}
                      filters={filters}
                      setFilters={setFilters}
                      renderData={dataEmployees}
                      setRenderData={setFilteredData}
                    />
                  </Paper>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};
