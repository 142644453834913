import { Chart } from "modules/Organization/Chart/";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const WorkTeam = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <Chart
      application="employee"
      user={{ employeeSelected: user }}
    />
  );
};
