import { postLogOut } from "../api";
import store from "@redux/store";

export const SignOut = () => {
  const state = store.getState();
  const { refreshToken } = state.authUser;

  postLogOut({ refresh_token: refreshToken })
    .then(() => {
      sessionStorage.clear();
      localStorage.clear();
      document.location.reload();
    })
    .catch((error) => {
      console.error("Failed to authenticate", error);
    });
};
