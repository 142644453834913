import { EmploymentSummary as OrgEmploymentsSummary } from "modules/Employees/Profiles/EmploymentInformation/EmploymentsSummary";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const EmploymentsSummary = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgEmploymentsSummary
      application="employee"
      user={{ employeeSelected: user }}
    />
  );
};
