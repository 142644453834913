import { useEffect } from "react";
import TemplateGridUI from "utils/Template";
import { Bilding } from "components/Bilding";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ACCOUNT,
  HOME,
  LERNING_CENTER,
  MEDICAL,
  PERFORMANCE_CENTER,
  PROFILE,
  PROFILE_MODULE,
  REQUESTS,
  RESOURCES,
  SHIFT_ATTENDANCE,
  SPECIAL_ROUTES,
  TOOLS,
} from "./routes";
import { authUserSelect } from "@redux/slices/authUser";
import { uiSelect } from "@redux/slices/ui";
import { useDispatch, useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  getEmploymentInfo,
  getLocations,
  getTimeZones,
  getTenantSettings,
} from "api";
import { setTimeZone } from "./redux/slices/ui";
import { TIME_ZONES } from "./timeZones";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/pt";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);
dayjs.extend(timezone);
import "./App.css";
import store from "@redux/store";

export const unrestrictedRoutes = [
  "/requests/signature",
  "/requests/history",
  "/notifications/center",
  "/presentation-letter",
];

const App = () => {
  const dispatch = useDispatch();

  const { accessToken, keycloakProfile, permissions, user } =
    useSelector(authUserSelect);
  const { tenant } = useSelector(tenantSettingsSelect);
  const { mainColor, mode } = useSelector(parameterizationSelect);
  const { preferences } = useSelector(uiSelect);

  const state = store.getState();
  const { timeZone } = state?.ui;

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mainColor,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            zIndex: 1059,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            zIndex: 1059,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            zIndex: 1060,
          },
        },
      },
    },
  });

  const routesGenerator = (module) => {
    return module.map(({ route, component }, index) => {
      if (
        keycloakProfile?.is_superuser ||
        permissions?.paths.includes(route) ||
        unrestrictedRoutes.includes(route)
      ) {
        return <Route key={index} path={route} element={component} />;
      }
    });
  };

  const routesModule = (modules) => {
    let routes = [];

    Object.keys(modules).forEach((key) => {
      const module = modules[key];

      if (module.hasOwnProperty("sections") && module.sections.length) {
        routes = routes.concat(routesGenerator(module.sections));
      } else {
        routes = routes.concat(routesGenerator([module]));
      }
    });

    return routes;
  };

  useEffect(() => {
    const locale = preferences?.language?.abb;

    if (timeZone) {
      dayjs.tz.setDefault(timeZone);
    }

    switch (locale) {
      case "es":
        dayjs.locale("es");
        dayjs.updateLocale("es", { weekStart: 0 });
        return dayjs;

      case "fr":
        dayjs.locale("fr");
        dayjs.updateLocale("fr", { weekStart: 0 });
        return dayjs;

      case "pt":
        dayjs.locale("pt");
        dayjs.updateLocale("pt", { weekStart: 0 });
        return dayjs;

      default:
        dayjs.locale("en");
        return dayjs;
    }
  }, [preferences?.language?.abb]);

  const timeZoneFunction = async (locale) => {
    try {
      const { data: location } = await getLocations();
      const timeZone = location?.find((l) => l?._id === locale)?.timeZone;

      if (timeZone) {
        const { data: TimeZones } = await getTimeZones({
          id: "id",
          value: timeZone,
        });

        const validTimeZone = TimeZones?.utc.find((tz) =>
          TIME_ZONES.includes(tz),
        );

        if (validTimeZone) {
          dispatch(setTimeZone(validTimeZone));
        } else {
          console.error("No valid time zone found in TIME_ZONES.");
        }
      }
    } catch (error) {
      console.log("error timeZoneFunction: ", error);
    }
  };

  useEffect(() => {
    const timeZone = async () => {
      try {
        const { data: EmploymentInfo } = await getEmploymentInfo(user._id);
        const { data: tenant } = await getTenantSettings();

        if (EmploymentInfo?.workLocation) {
          timeZoneFunction(EmploymentInfo?.workLocation);
        } else if (tenant?.mainLocation) {
          timeZoneFunction(tenant?.mainLocation);
        } else {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          dispatch(setTimeZone(timeZone));
        }
      } catch (error) {
        console.log("error timeZone: ", error);
      }
    };
    if (user._id) {
      timeZone();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {accessToken !== null ? (
        <TemplateGridUI>
          <Routes>
            {keycloakProfile?.is_superuser && !tenant?.wizard?.completed ? (
              <Route path="*" element={<Navigate to="/wizard" />} />
            ) : keycloakProfile?.is_superuser ||
              permissions?.apps?.employees === true ? (
              <>
                <Route path="/" element={<Bilding />} />
                {routesGenerator(HOME)}
                {/* {routesGenerator(ACCOUNT)} */}
                {routesGenerator(REQUESTS)}
                {routesGenerator(PROFILE)}
                {routesGenerator(SHIFT_ATTENDANCE)}
                {/* {routesGenerator(LERNING_CENTER)} */}
                {/* {routesGenerator(PERFORMANCE_CENTER)} */}
                {/* {routesGenerator(RESOURCES)} */}
                {/* {routesGenerator(MEDICAL)} */}
                {/* {routesGenerator(TOOLS)} */}
                {routesGenerator(SPECIAL_ROUTES)}
                {routesModule(PROFILE_MODULE)}
              </>
            ) : (
              <Route path="*" element={<Navigate to="/auth/not" />} />
            )}
          </Routes>
        </TemplateGridUI>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
};

export default App;
