import {
  Drawer,
  Typography,
  Divider,
  Stack,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

export const Help = ({ stateDrawer, toggleDrawer, DrawerHeaderLeft }) => {
  return (
    <Drawer
      anchor="right"
      open={stateDrawer["right"]}
      onClose={toggleDrawer("right", false)}
      sx={{
        "&.MuiDrawer-root .MuiDrawer-paper": {
          maxWidth: 340,
          minWidth: 340,
        },
      }}
    >
      <DrawerHeaderLeft>
        <Typography variant="h6">Descripción de ayuda</Typography>
      </DrawerHeaderLeft>
      <Divider />

      <Stack
        spacing={2}
        sx={{
          margin: "15px",
        }}
      >
        <Card variant="outlined">
          <CardContent>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              dignissim leo ac interdum feugiat. Interdum et malesuada fames ac
              ante ipsum primis in faucibus
            </Typography>
          </CardContent>
          <CardActions></CardActions>
        </Card>

        <Button
          size="large"
          variant="outlined"
          startIcon={<ChatBubbleOutlinedIcon />}
          sx={{
            justifyContent: "flex-start",
          }}
        >
          Soporte por chat
        </Button>
        <Button
          size="large"
          variant="outlined"
          startIcon={<MailOutlineIcon />}
          sx={{
            justifyContent: "flex-start",
          }}
        >
          Crear ticket
        </Button>
      </Stack>
    </Drawer>
  );
};
