import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setExpandedSearch } from "@redux/slices/general";
import { setOpenDialogSearch } from "@redux/slices/general";
import { generalSelect } from "@redux/slices/general";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
} from "@mui/icons-material";
import { getSearchAll, getEmployee } from "api";
import { useState } from "react";
import { setEmployeeSelected } from "@redux/slices/profile";
import { setTextSearchDialog } from "@redux/slices/general";
import { PROFILE_MODULE } from "../../../routes";
import { useTranslation } from "react-i18next";
import { parameterizationSelect } from "@redux/slices/parameterization";
import reactStringReplace from "react-string-replace";

export const DialogSearch = () => {
  const { t } = useTranslation(["organization"]["general"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { openDialogSearch, expandedSearch } = useSelector(generalSelect);
  const [searchData, setSearchData] = useState({});
  const { mainColor } = useSelector(parameterizationSelect);
  const [textSearch, setTextSearch] = useState("");

  let delayTimer;
  const personal_information = PROFILE_MODULE.Collaborator.sections.find((
    section,
  ) => section.name === "PersonalInformation");

  const startSearch = (event) => {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      const fetchDataSearch = async () => {
        setTextSearch(event.target.value);
        const { data } = await getSearchAll(event.target.value);
        dispatch(setExpandedSearch(true));
        dispatch(setTextSearchDialog(event.target.value));
        setSearchData(data);
      };

      fetchDataSearch();
    }, 500);
  };

  const actionOnClick = async (id) => {
    try {
      const { data } = await getEmployee("id", id);
      dispatch(setOpenDialogSearch(false));
      dispatch(setExpandedSearch(false));
      dispatch(setEmployeeSelected(data));

      if (window.location.pathname == personal_information.route) {
        setTimeout(() => {
          window.location.href = personal_information.route;
        }, 200);
      } else {
        navigate(personal_information.route);
      }
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };

  const ItemResult = ({ id, text }) => {
    text = reactStringReplace(
      text,
      textSearch,
      (match, i) => (
        <span
          key={i}
          style={{
            fontWeight: "bold",
            color: mainColor,
          }}
        >
          {match}
        </span>
      ),
    );

    return (
      <ListItemButton
        key={id}
        onClick={() => {
          actionOnClick(id);
        }}
      >
        <ListItemText id={id} primary={text} />
      </ListItemButton>
    );
  };

  const GenerateResultsList = () => {
    return (
      <>
        {Object.entries(searchData).map(([key, values]) => (
          <List
            subheader={
              <ListSubheader component="div">
                <Typography variant="overline">
                  {t(`employee:${key}`)}
                </Typography>
              </ListSubheader>
            }
            key={key}
          >
            {(Object.keys(values).length > 0)
              ? (Object.entries(values).map(([id, text]) => (
                <ItemResult key={id} id={id} text={text} />
              )))
              : (
                <ListItem>
                  <ListItemIcon>
                    <SentimentVeryDissatisfiedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Without results"} />
                </ListItem>
              )}
          </List>
        ))}
      </>
    );
  };

  return (
    <Dialog
      open={openDialogSearch}
      onClose={() => {
        dispatch(setOpenDialogSearch(false));
        dispatch(setExpandedSearch(false));
      }}
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <SearchIcon
            sx={{
              mr: 1,
              my: 0.5,
            }}
          />
          <TextField
            variant="standard"
            fullWidth={true}
            id="txt-modal-search"
            expand={expandedSearch.toString()}
            aria-expanded={expandedSearch}
            onChange={startSearch}
            autoFocus={true}
          />
        </Box>
      </DialogTitle>
      <Collapse in={expandedSearch} timeout="auto" unmountOnExit>
        <DialogContent>
          <GenerateResultsList />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/cereza-organization/search/advanced");
              dispatch(setOpenDialogSearch(false));
              dispatch(setExpandedSearch(false));
            }}
          >
            Advanced search
          </Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};
