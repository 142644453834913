import * as React from "react";
import i18n from "i18next";
import { useState } from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  MenuList,
} from "@mui/material";
import { Translate, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { uiSelect } from "@redux/slices/ui";
import { setUserPreferences } from "@redux/slices/ui";
import { authUserSelect } from "@redux/slices/authUser";
import { useTranslation } from "react-i18next";
import { updateUserPreferences } from "api";

export const Language = () => {
  const { t } = useTranslation(["sidenav"]);

  const { user } = useSelector(authUserSelect);

  const changeLanguage = async (lng) => {
    if (i18n && i18n.changeLanguage) {
      i18n.changeLanguage(lng.abb);
    }

    const newPreferences = { ...preferences, language: lng };
    dispatcher(setUserPreferences(newPreferences));

    try {
      await updateUserPreferences(user.userId, newPreferences);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatcher = useDispatch();
  const { preferences } = useSelector(uiSelect);

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <Translate />
        </ListItemIcon>
        <ListItemText>{t("Language")}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MenuList component="div" disablePadding>
          <MenuItem
            selected={"es" === preferences.language.abb}
            onClick={() => {
              changeLanguage({ abb: "es", name: "Español" });
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }}
          >
            <ListItemIcon />
            <ListItemText>Español</ListItemText>
          </MenuItem>
          <MenuItem
            selected={"en" === preferences.language.abb}
            onClick={() => {
              changeLanguage({ abb: "en", name: "English" });
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }}
          >
            <ListItemIcon />
            <ListItemText>English</ListItemText>
          </MenuItem>
          <MenuItem
            selected={"fr" === preferences.language.abb}
            onClick={() => {
              changeLanguage({ abb: "fr", name: "Français" });
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }}
          >
            <ListItemIcon />
            <ListItemText>Français</ListItemText>
          </MenuItem>
          <MenuItem
            selected={"pt" === preferences.language.abb}
            onClick={() => {
              changeLanguage({ abb: "pt", name: "Português" });
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }}
          >
            <ListItemIcon />
            <ListItemText>Português</ListItemText>
          </MenuItem>
        </MenuList>
      </Collapse>
    </>
  );
};
